<template>
  <v-container>
      <p class="text-center text-h3 my-10 titulo-card">Account Settings</p>
    <div class="info-teacher text-center">
        <p>Name: {{ user.Name }} {{ user.LastName }}</p>
        <!-- <p>Licence expiration: {{ user.licenseExpirationDate }}</p> -->
    </div>
      <v-card max-width="960" class="mx-auto my-5" v-if="payment != ''">
        <v-toolbar flat color="cyan lighten-3">
            <v-toolbar-title>Manage your subscription</v-toolbar-title>
        </v-toolbar>
        <v-row class="my-2">
            <v-col cols="5" class="my-auto">
              <p class="text-center text-h6">Date of purchase: {{ payment.PurchaseDate }}</p>
            </v-col>
            <v-col cols="4">
              <p class="text-center text-h6">Estate: {{payment.Estate}}</p>
            </v-col>
            <v-col cols="3" class="my-auto">
              <v-btn
                depressed
                color="primary"
              >
                Unsubscribe 
              </v-btn>
            </v-col>
        </v-row>
      </v-card>
      <v-card max-width="960" class="mx-auto" v-if="!isSchoolTeacher">
        <v-toolbar flat color="cyan lighten-3">
            <v-toolbar-title>Change your password</v-toolbar-title>
        </v-toolbar>
        <v-row class="my-2">
            <v-col cols="5" class="my-auto">
              <p class="text-center text-h6">Current Password</p>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined label="Current Password" prepend-icon="mdi-lock" v-model="currentPassword" autocomplete="off" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" counter @click:append="showPassword = !showPassword"></v-text-field>
            </v-col>
            <v-col cols="5" class="my-auto">
              <p class="text-center text-h6">New Password</p>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined label=" New Password" prepend-icon="mdi-lock" v-model="newPassword" autocomplete="off" :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword2 ? 'text' : 'password'" counter @click:append="showPassword2 = !showPassword2"></v-text-field>
            </v-col>
        </v-row>
        <v-card-actions>
            <v-btn class="ma-auto" :loading="loadingPassword" :disabled="loadingPassword" color="secondary" @click="ChangePassword">
              Update Password
            </v-btn>
        </v-card-actions>
        <v-alert border="top" colored-border type="info" elevation="2" v-if="textUpdatePassword !=''">
            {{ textUpdatePassword }}
        </v-alert>
      </v-card>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { auth ,db } from '../../../plugins/firebase'
import firebase from 'firebase'

export default {
  data() {
    return {
        currentPassword:'',
        newPassword:'',
        loadingPassword: false,
        textUpdatePassword:'',
        showPassword: false,
        showPassword2: false,
        user: {},
        payment: '',
        isSchoolTeacher:false,
    }
  },
  created () {
    console.log(this.$store.state.user.role);
    if (this.$store.state.user.role == "schoolTeacher") {
      this.isSchoolTeacher = true;
    }
  },
  computed: {
    ...mapState('userTeacher', ['userTeacher'])
  },
  watch: {
    userTeacher: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.GetDataTeacher();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async GetDataTeacher() {
      let iud = this.$store.state.user.uid;
      let role=this.$store.state.user.role;
      let info ='';
      if (role == 'individualTeacher') {
        info = await db.collection("IndividualTeacher").doc(iud).get();
        this.user = { Name: info.data().Name , LastName: '', LicenseExpirationDate:info.data().LicenseExpirationDate }
        if (info.data().Full) {
          if (info.data().PaymentsLog) {
            let paymentsLog = info.data().PaymentsLog
          
            console.log(paymentsLog.length)
            this.payment = paymentsLog[paymentsLog.length-1]
          }
          
        }
      }else{
        // info = await db.collection("SchoolTeacher").doc(iud).get();
        // this.user=info.data();
        if (this.userTeacher && Object.keys(this.userTeacher).length) {
          this.user=this.userTeacher;
        } else {
          console.log('userTeacher aún no está disponible');
        }
      }  
    },
    ChangePassword () {
      if (this.newPassword == '' || this.currentPassword == '') {
        this.textUpdatePassword = 'required current password and new password';
        this.newPassword= '';
        this.currentPassword= '';
        setTimeout(() => (this.textUpdatePassword = ''), 3000);
        return;
      }
        this.loadingPassword=true;
        let aux=this;
        const user = auth().currentUser;
        const credential = auth.EmailAuthProvider.credential(
            user.email, 
            this.currentPassword
        );
        // Now you can use that to reauthenticate
        user.reauthenticateWithCredential(credential).then(()=>{
            user.updatePassword(this.newPassword).then(function() {          
                aux.dialogSettings = false;
                aux.loadingPassword=false;
                aux.snackbar =true;
                aux.newPassword= '';
                aux.currentPassword= '';
                aux.textUpdatePassword = 'password changed successfully '
            }).catch(function(error) {
                console.log(error)
                aux.loadingPassword=false;
                aux.textUpdatePassword = error.message;
                setTimeout(() => (aux.textUpdatePassword = ''), 3000);
            });
        }).catch((error)=>{
            aux.loadingPassword=false;
            aux.textUpdatePassword = error.message;
            setTimeout(() => (aux.textUpdatePassword = ''), 3000);
        });
      
        
    },
  },
  mounted() {
    this.GetDataTeacher();
  }
}
</script>
<style>
    .info-teacher{
        font-size: 20px;
    }
</style>